import React from "react";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import TitleDescImg from "../../components/TitleDescImg/TitleDescImg";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "./Vendeglatas.scss";
import CMOButton from "../../components/Button/Button";
import { navigate } from "gatsby";
import docs_background_image from "../../assets/images/Documentation/block-background-image.jpg";

class VendeglatasPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      PageHeader,
      SimpleAndFastBlock,
      CompleteSolution,
      Licensing,
      WeCareAbountYou,
      Documentation,
      SEO,
    } = this.props.pageContext.content;

    const location_href = this.props.location.pathname;

    return (
      <div className="vendeglatas-wrapper">
        <LayoutHeaderFooter
          header_background_color={"#FFAA2A"}
          header_secondary_color={"#FFAA2A"}
          header_no_fade={true}
          SEO_title={SEO.title}
          SEO_description={SEO.description}
          SEO_keywords={SEO.keywords}
          SEO_image={SEO.image}
          SEO_location={location_href}
        >

          <div className="page-header-wrapper">
            <div className="decoration" style={{ background: "linear-gradient(180deg, #FFAA2A 33.02%, #FFCA25 104.32%)" }}>
              {
                <div className="subpage">
                  <div className="firstComponent-content" style={{ padding: 0 }}>
                    <section className="section-content">
                      <h1>{PageHeader.Title}</h1>
                      <div className="description">
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={PageHeader.Description}
                        />
                      </div>
                    </section>
                    <section className="section-image">
                      <div className="firstComponent-image">
                        <img
                          className="ill-image desktop"
                          src={PageHeader.Image ? process.env.GATSBY_CMS_URL + PageHeader.Image?.formats.small.url : ""}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="licensingBlock">
            <div className="content">
              <h2 className="title">{Licensing.Title}</h2>
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={Licensing.Description}
                />
              </div>

            </div>
            <div className="bundles-section">
              {Licensing.LicenseType.map((item, index) => (

                <div className="bundle" key={`bundle-${index}`}
                >
                  <div className="bundle-title" style={{ backgroundColor: item.HeaderBackgroundColor, color: item.HeaderTextColor }} >
                    <h3>{item.Name}</h3>
                  </div>
                  <div className="bundle-content" >
                    <div className="bundle-image">
                      <img src={`${process.env.GATSBY_CMS_URL}${item.BundleImage?.url}`} alt={item.BundleImage?.alternativeText} style={{ width: "160px", height: "160px" }} />
                    </div>
                    <div className="bundle-description-wrapper">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={item.BundleDescription}
                      />
                    </div>
                  </div>
                  <div className="bundle-links">
                    <CMOButton
                      onClick={() => navigate(item.ButtonLink)}
                      variant="contained"
                      className="bundle-links"
                      background_color={"#5107ff"}
                      text={item.ButtonText}
                      color={"white"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div loading="lazy"
            className="docs-block"
            style={{
              backgroundImage: `url(${docs_background_image})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}>
            <LayoutComponent>
              <div className="docs-block-content">
                <div className="docs-text">
                  <div className="docs-title">
                    <p>{Documentation.Title}</p>
                  </div>
                  <div className="docs-description">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={Documentation.Description}
                    />
                  </div>
                </div>
                <CMOButton
                  background_color={"#5107ff"}
                  color={"#FFFFFF"}
                  text={Documentation.ButtonText}
                  onClick={() => { navigate("/gyik/epenztargep/ntak.vendeglato.szoftver-onallo-telepitese"); }} />
              </div>
            </LayoutComponent>
          </div>
          <div className="careAboutYouBlock">
            <div className="careAboutYouBlock-content">
              <h2 className="careAboutYouBlock-content-title">{WeCareAbountYou.Title}</h2>
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={WeCareAbountYou.Description}
                />
              </div>
              <div className="goodnews-wrapper">
                <div className="title">{WeCareAbountYou.FollowupTitle}</div>
                <ReactMarkdown
                  className="description2"
                  rehypePlugins={[rehypeRaw]}
                  children={WeCareAbountYou.FollowupDescription}
                />
              </div>
            </div>
          </div>
         
          <TitleDescImg
            title={
              SimpleAndFastBlock.Title
            }
            description={
              SimpleAndFastBlock.Content
            }
            image={SimpleAndFastBlock.Image ? process.env.GATSBY_CMS_URL + SimpleAndFastBlock.Image?.url : ""}
            imageClass={"title-desc-img-img"}
            descriptionClass={"title-desc-img-desc"}
          />

          <div className="completeSolution-wrapper" style={{ backgroundColor: "#FFFFFF" }}>
            <div className="completeSolution-body-wrapper">
              <div className="completeSolution-body-content">
                <section className="completeSolution-image-section" >
                  <div className="completeSolution-image-wrapper" style={{ width: "600px", height: "600px" }}>
                    <img src={process.env.GATSBY_CMS_URL + CompleteSolution.Image.url} />
                  </div>
                </section>
                <section className="completeSolution-content-section">
                  <h2 className="completeSolution-content-title title">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={CompleteSolution.Title}
                    />
                  </h2>
                  <div className="completeSolution-content-description description">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={CompleteSolution.Content}
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </LayoutHeaderFooter>
      </div>
    );
  };
}

export default VendeglatasPage;
